/* .WaitingOverlay {
    width: 100%;
    height: 100vh;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);   
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    display: grid;
    align-items: center;
    justify-content: center;
}

.WaitingOverlay:before {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -ms-filter: blur(20px);
    -o-filter: blur(20px);
    filter: blur(20px); 
    margin: -20px;
} */
.WaitingOverlay__loader {
    height: 32px;
    width: 32px;
    margin-left: auto;
    margin-right: auto;
    border: 5px solid #Fff;
    border-radius: 10px;
    animation: loader 2s infinite ease;
  }
  
  .WaitingOverlay__panel {
      display: grid;
      align-items: center;
      justify-content: center;
      background: #1D1D1D;
      width:300px;
      height:150px;
      border: 1px solid rgba(255, 255, 255, 0.1);
  }
  .WaitingOverlay__loader-text {
      margin-top:25px;
  }
@keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    
    25% {
      transform: rotate(180deg);
    }
    
    50% {
      transform: rotate(180deg);
    }
    
    75% {
      transform: rotate(360deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes loader-inner {
    0% {
      height: 0%;
    }
    
    25% {
      height: 0%;
    }
    
    50% {
      height: 100%;
    }
    
    75% {
      height: 100%;
    }
    
    100% {
      height: 0%;
    }
  }