// $w : 16;
// $h : 9;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap");
html {
  height: 100%;
  // overflow:hidden;
}
body {
  background: #1a1a1a;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#root {
  position: relative;
  width: 100%;
  // max-width: $w / $h *  100vh;
  &:after{
    content: '';
    display: block;
    // padding-bottom: ($h / $w) * 100%;
  }  
}
.App {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  
  box-sizing: border-box;
  
  &:after{
    margin: auto;
    display: block;
  }
}

.content{
  background: #555;
  box-shadow: inset 1vh 1vh 10vh 0 rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  
  box-sizing: border-box;
  border: 25px solid #cecece;
  
  &:after{
    margin: auto;
    display: block;
    // content: $w + ':' + $h;
    color: #fff;
    position: relative;
  }
}
.dot {
  height: 32px;
  width: 32px;
  
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #333333;
  margin-left: 34px;
}
.redDot {
  background-color: rgb(201, 12, 12);
  box-shadow:  0 0 16px rgba(201, 12, 12, 0.25);
  -moz-box-shadow: 0 0 16px rgba(201, 12, 12, 0.25);
  -webkit-box-shadow: 0 0 16px rgba(201, 12, 12, 0.25);
  -o-box-shadow: 0 0 16px rgba(201, 12, 12, 0.25);
}
.yellowDot {
  background-color: rgb(201, 198, 12);
  box-shadow:  0 0 16px rgba(198, 201, 12, 0.25);
  -moz-box-shadow: 0 0 16px rgba(198, 201, 12, 0.25);
  -webkit-box-shadow: 0 0 16px rgba(198, 201, 12, 0.25);
  -o-box-shadow: 0 0 16px rgba(198, 201, 12, 0.25);
}
.greenDot {
  background-color: rgba(34,201,12, 1);
  box-shadow:  0 0 16px rgba(34,201,12, 0.25);
  -moz-box-shadow: 0 0 16px rgba(34,201,12, 0.25);
  -webkit-box-shadow: 0 0 16px rgba(34,201,12, 0.25);
  -o-box-shadow: 0 0 16px rgba(34,201,12, 0.25);
}