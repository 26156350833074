@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap);

a:visited {
  color: #777;
}
.onePanel {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  resize: horizontal;
  width: 100%;
}
.oneUp__fullscreenbutton {
  position: absolute;
  right:5px;
  top:5px;
  cursor: pointer;
}
.onePanel__panel {
  background: #272727;
  border: 1px solid #16171a;
  border: 1px solid #4c4c4c;
  box-shadow: inset 0 0 2px 0 rgba(92, 92, 92, 0.05);
  border-radius: 8.4px;
  width: 100%;  
  display: grid;
  grid-row: 3;
  grid-auto-flow: row dense;
  grid-auto-rows: auto auto auto;
  align-content: start;
  height: 100%;
}
.oneUp__header {
  display: grid;
  background: #232323;
  border: 1px solid #191919;
  border-radius: 7.35px 7.35px 0 0;
  border-bottom: 1px solid #454545;
  width: calc(100% - 2px);
  height: 6.125em;
  grid-auto-flow: column;
  align-items: center;
  justify-content: left;
  grid-auto-columns: -webkit-min-content -webkit-max-content 1fr;
  grid-auto-columns: min-content max-content 1fr;
  min-height: 64px;
}
.oneUp__header-text {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 4.500em;
  color: #7c7c7c;
  letter-spacing: 0;
  margin-left: 34px;
  text-transform: uppercase;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  cursor: pointer;
  width: 100%;
}

.oneUp__tablefeedcontainer {
  /* overflow-y: auto; */
  width: 100%;
}
.oneUp__droppable {
  width: 100%;
}
::-webkit-scrollbar {
  display: none;
}
.oneUp__tablefeed {
  white-space: nowrap;
  box-sizing: content-box;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  overflow: -moz-scrollbars-none;
  padding-bottom: 20px;
  
  /* height: 1000px; */
  /* height:3em; */
}
.oneUp__tablefeed-empty {
  /* background-color: #f55; */
  /* white-space: nowrap;
  overflow-y: scroll;
  box-sizing: content-box;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  overflow: -moz-scrollbars-none; */
  padding-bottom: 20px;
  height: 100%;
  width: 100%;
  /* height: 100px; */
  /* width:100px; */
}

.oneUp__table-row-content {
  display: grid;
  grid-auto-flow: column;
  height: 3.375em;
  background: #19191a;
  border: 1px solid #0b0b0b;
  box-shadow: 0 1px 1px -1px rgba(255, 255, 255, 0.5),
    inset 0 2px 17px 0 rgba(0, 0, 0, 0.5);
  border-radius: 8.4px;
  margin-left: 20px;
  margin-right: 20px;
  grid-auto-columns: auto 1px 10.688em 1px 16.250em 1px 16.250em;
}
.oneUp__table-row-content-warning {
  display: grid;
  grid-auto-flow: column;
  height: 5.982142855em;
  background: #19191a;
  border: 1px solid #0b0b0b;
  box-shadow: 0 1px 1px -1px rgba(255, 255, 255, 0.5),
    inset 0 2px 17px 0 rgba(0, 0, 0, 0.5);
  border-radius: 8.4px;
  margin-left: 20px;
  margin-right: 20px;
  grid-template-columns: auto 1px 10.688em 1px 16.250em 1px 16.250em;
  grid-column-start: 1;
  grid-column-end: 8;
  grid-template-rows: 3.375em 2.607142855em;
}
.oneUp__tablerowbodyspan-warning {
  border-top: 1px solid #323234;
  display: grid;
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-end: 2;
  justify-content: left;
  align-content: center;
}
.oneUp__table-row-content-inactive {
  background: #363636;
}
.oneUp__table-row-content-played {
  background: #307A23;
}
.separater {
  border: 1px solid #454545;
}
.oneUp__table-header {
  margin-top: 28px;
  margin-bottom: 0px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  width: 100%;
}
.oneUp_rowheaderspan {
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 2.362em;
  color: #ffffff;
}
.oneUp_headerseparator {
  background-color: #0f0f0f;
  grid-row-start: 1;
  grid-row-end: 1;
  width: 2px;
}

.oneUp__table-duration {
  display: grid;
  justify-content: center;
  grid-row-start: 1;
  grid-row-end: 1;
}
.oneUp__table-created {
  display: grid;
  justify-content: center;
  grid-row-start: 1;
  grid-row-end: 1;
}
.oneUp__tablebody {
  margin-top: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  /* width: 100%; */
}
.oneUp__tablerowbodyspan {
  align-items: center;
  font-size: 2.625em;
  color: #ffffff;
}
.oneUp__bodyseparator {
  background-color: #0f0f0f;
  width: 2px;
  height: 3.375em;
}
.oneUp_doneButton {
  width: 2.354333333em;
  height: 0.916666667em;
}
.oneUp__dropdown {
  margin: 10px;
}
.oneUp__table-body-name {
  display: grid;
  grid-row: 1;
  grid-template-columns: 2.85em 1fr 1fr;
  grid-template-rows: 1em;
  margin-left: 10px;
  text-transform: uppercase;
  align-content: center;
}
.oneUp__table-body-name-standarduser {
  display: grid;
  grid-row: 1;
  grid-template-columns: 1fr 1fr;
  margin-left: 20px;
  text-transform: uppercase;
}
.oneUp__table-body-nametext {
  grid-row: 1;
  grid-column: 2 / 4;
  white-space:nowrap;
  margin-top: 3px; /*HACK*/
  /* overflow:hidden; */ 
  background: linear-gradient(to right, rgba(255,255,255,1) 2%,rgba(255,255,255,1) 90%,rgba(255,255,255,0) 100%); 
  text-overflow: ellipsis;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: -2.9em;
  /* justify-self: left; */
}
.oneUp__table-body-nametext-standardUser {
  grid-row: 1;
  grid-column: 1 / 3;
}
.oneUp__table-body-warning {
  grid-row: 1;
  grid-column: 2 / 4;
  justify-self: end;
  background: #d0021b;
  border-radius: 0.4em;
  padding-left: 0.35em;
  padding-right: 0.35em;
  padding-bottom: 0.1em;
  margin-right: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
  -webkit-filter: drop-shadow(-10px 0 2rem rgba(0, 0, 0, 0.5));
          filter: drop-shadow(-10px 0 2rem rgba(0, 0, 0, 0.5));
  white-space:nowrap;
  overflow:hidden;
  /* max-width: 100%;
  max-height: 90%; */
  height: 1em;
  margin-left: 10px;
  font-size: 2.5em;
}
.oneUp__table-body-warning-single {
  grid-row: 1;
  grid-column: 2 / 4;
  justify-self: end;
  background: #d0021b;
  border-radius: 0.4em;
  padding-left: 0.35em;
  padding-right: 0.35em;
  padding-bottom: 0.1em;
  margin-right: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
  -webkit-filter: drop-shadow(-10px 0 2rem rgba(0, 0, 0, 0.5));
          filter: drop-shadow(-10px 0 2rem rgba(0, 0, 0, 0.5));
  white-space:nowrap;
  overflow:hidden;
  /* max-width: 100%;
  max-height: 90%; */
  height: 1em;
  margin-left: 10px;
  /* font-size: 2em; */
}
.oneUp__table-alert {
  width: 0.6em;
  height: 0.6em;
  padding-left: 5px;
  padding-right: 10px;
}
.oneUp__table-lsm {
  display: grid;
  justify-content: center;
}
.oneUp__donebuttoncontainer {
  margin-right: 10px;
  margin-left: 0px;
  display: grid;
  align-items: center;
  justify-content: left;
  height: 100%;
}
.oneUp__spincontainer {
  margin-left: 10px;
}

.react-tiny-popover-container {
  -webkit-filter: drop-shadow(-0px 0 8rem rgba(0, 0, 0, 1));
          filter: drop-shadow(-0px 0 8rem rgba(0, 0, 0, 1));
}
.oneUp__binpopover {
  background: #1a1a1a;
  border: 1px solid #595959;
  box-shadow: inset 0 1px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8.4px;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-content: left;
  font-weight: 900;
  font-size: 3.000em;
  color: #ffffff;
  letter-spacing: 0;
}
.oneUp__binpopovername {
  border-bottom: 1px solid #3b3b3b;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: left;
  text-transform: uppercase;
}
.oneUp__binpopovername:last-child {
  border-bottom: 0;
}
.oneUp__binpopover-container {
  height: 12em;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-content: left;
}
.oneUp__binpopovername-text {
  margin-left: 20px;
  margin-right: 150px;
  justify-self: start;
}
.oneUp__binpopovername:hover {
  background: #0075e1;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.oneUp__gear {
  justify-self: end;
  margin-right: 34px;
  cursor: pointer;
}
.oneUp__preferencespopovercontainer {
  width: 850px;
  min-width: 320px;
  min-height: 120px;
}
.oneUp__preferencespopover {
  background: #1a1a1a;
  border: 1px solid #595959;
  box-shadow: 0 2px 32px 5px rgba(0, 0, 0, 0.5),
    inset 0 1px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-content: left;
  font-weight: 900;
  font-size: 36px;
  color: #ffffff;
  letter-spacing: 0;
  margin-right: 50px;
  height: 8em;
  text-transform: uppercase;
  min-width: 320px;
  min-height: 120px;
}
.oneUp_prefsettingtext {
  text-transform: uppercase;
  margin-left: 20px;
  width: 14em;
}
.oneUp_settingcontainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-auto-columns: 1fr 1fr;
}
.oneUp__rowsizecontainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-auto-columns: 1fr 1fr;
}
.oneUp__rowsizevalue {
  margin-left: 20px;
  margin-right: 20px;
}

.oneUp_viewtypecontainersingle {
  background: #0075e1;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  justify-self: end;
  margin-right: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
}
.oneUp__rowsizecontrolscontainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-self: end;
  margin-right: 20px;
}
.dropdown_small {
  margin-left: 10px;
} 
::-webkit-scrollbar {
  width: 0px;
  background: transparent; 
}
.oneUp__adminlinecontainer {
  margin-top: 0.625em;
  height: 5px;
  width: 100%;
  /* width: 1916px; */
  /* position: absolute; */
  overflow: auto;
  /* margin-top: 896px; */
}
.oneUp__adminline {
  background: #fff;
  height: 3px;
  width: 100%;
}
.oneUp__fontsizeinput  {
  background:none;
  outline: none;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 1em;
  color:#fff;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border-width: 0 0 1px;
  border-radius: 0px;
  -webkit-border-radius:0px;
  -moz-border-radius:0px;
  outline:none;
  text-align: center;
  width: 4em;
}
.oneUp__whiteseparator {
  background-color: #FFF;
  /* margin-top: calc((3.5em * 0) + (15px * 0) - 8px); */
  height: 3px;
  width: 100%;
  position: absolute;
}
.oneUp_logout {
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.5em;
}
.oneUp__prefinfo {
  font-size: 0.5em;
  color:#555;
  width: 26em;
}
/* .WaitingOverlay {
    width: 100%;
    height: 100vh;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);   
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    display: grid;
    align-items: center;
    justify-content: center;
}

.WaitingOverlay:before {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -ms-filter: blur(20px);
    -o-filter: blur(20px);
    filter: blur(20px); 
    margin: -20px;
} */
.WaitingOverlay__loader {
    height: 32px;
    width: 32px;
    margin-left: auto;
    margin-right: auto;
    border: 5px solid #Fff;
    border-radius: 10px;
    -webkit-animation: loader 2s infinite ease;
            animation: loader 2s infinite ease;
  }
  
  .WaitingOverlay__panel {
      display: grid;
      align-items: center;
      justify-content: center;
      background: #1D1D1D;
      width:300px;
      height:150px;
      border: 1px solid rgba(255, 255, 255, 0.1);
  }
  .WaitingOverlay__loader-text {
      margin-top:25px;
  }
@-webkit-keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    
    25% {
      transform: rotate(180deg);
    }
    
    50% {
      transform: rotate(180deg);
    }
    
    75% {
      transform: rotate(360deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  }
@keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    
    25% {
      transform: rotate(180deg);
    }
    
    50% {
      transform: rotate(180deg);
    }
    
    75% {
      transform: rotate(360deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes loader-inner {
    0% {
      height: 0%;
    }
    
    25% {
      height: 0%;
    }
    
    50% {
      height: 100%;
    }
    
    75% {
      height: 100%;
    }
    
    100% {
      height: 0%;
    }
  }
  
  @keyframes loader-inner {
    0% {
      height: 0%;
    }
    
    25% {
      height: 0%;
    }
    
    50% {
      height: 100%;
    }
    
    75% {
      height: 100%;
    }
    
    100% {
      height: 0%;
    }
  }
.panelSet {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
}
.twoPanel:first-child {
  padding-right:10px;
}
.twoPanel:last-child {
  padding-left:10px;
}
.twoPanel {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  resize: horizontal;
  width: 100%;
  display: flex;
  align-content:start;
  
}

.twoUp__fullscreenbutton {
  position: absolute;
  right:5px;
  top:5px;
  cursor: pointer;
}
.twoPanel__panel {
  background: #272727;
  border: 1px solid #16171a;
  border: 1px solid #4c4c4c;
  box-shadow: inset 0 0 2px 0 rgba(92, 92, 92, 0.05);
  border-radius: 8.4px;
  width: 100%;  
  display: grid;
  grid-row: 3;
  grid-auto-flow: row dense;
  grid-auto-rows: auto auto auto;
  align-content: start;
  height: 100%;
}
.twoUp__header {
  display: grid;
  background: #232323;
  border: 1px solid #191919;
  border-radius: 7.35px 7.35px 0 0;
  border-bottom: 1px solid #454545;
  width: calc(100% - 2px);
  height: 6.125em;
  grid-auto-flow: column;
  align-items: center;
  justify-content: left;
  grid-auto-columns: -webkit-min-content -webkit-max-content 1fr;
  grid-auto-columns: min-content max-content 1fr;
  min-height: 64px;
}
.twoUp__header-text {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 4.500em;
  color: #7c7c7c;
  letter-spacing: 0;
  margin-left: 34px;
  text-transform: uppercase;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  cursor: pointer;
  width: 100%;
}

.twoUp__tablefeedcontainer {
  overflow-y: auto;
  width: 100%;
}
.twoUp__droppable {
  width: 100%;
}
::-webkit-scrollbar {
  display: none;
}
.twoUp__tablefeed {
  white-space: nowrap;
  box-sizing: content-box;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  overflow: -moz-scrollbars-none;
  padding-bottom: 20px;
}
.twoUp__tablefeed-empty {
  padding-bottom: 20px;
  height: 100%;
  width: 100%;
}

.twoUp__table-row-content {
  display: grid;
  grid-auto-flow: column;
  height: 3.375em;
  background: #19191a;
  border: 1px solid #0b0b0b;
  box-shadow: 0 1px 1px -1px rgba(255, 255, 255, 0.5),
    inset 0 2px 17px 0 rgba(0, 0, 0, 0.5);
  border-radius: 8.4px;
  margin-left: 20px;
  margin-right: 20px;
  grid-auto-columns: auto 1px 10.688em 1px 16.250em;
}
.twoUp__table-row-content-warning {
  display: grid;
  grid-auto-flow: column;
  height: 5.982142855em;
  background: #19191a;
  border: 1px solid #0b0b0b;
  box-shadow: 0 1px 1px -1px rgba(255, 255, 255, 0.5),
    inset 0 2px 17px 0 rgba(0, 0, 0, 0.5);
  border-radius: 8.4px;
  margin-left: 20px;
  margin-right: 20px;
  grid-template-columns: auto 1px 10.688em 1px 16.250em;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-template-rows: 3.375em 2.607142855em;
}
.twoUp__tablerowbodyspan-warning {
  border-top: 1px solid #323234;
  display: grid;
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 2;
  justify-content: left;
  align-content: center;
}
.twoUp__table-row-content-inactive {
  background: #363636;
}
.twoUp__table-row-content-played {
  background: #307A23;
}
.separater {
  border: 1px solid #454545;
}
.twoUp__table-header {
  margin-top: 28px;
  margin-bottom: 0px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  width: 100%;
}
.twoUp_rowheaderspan {
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 2.362em;
  color: #ffffff;
}
.twoUp_headerseparator {
  background-color: #0f0f0f;
  width: 2px;
}

.twoUp__table-duration {
  display: grid;
  justify-content: center;
}
.twoUp__table-created {
  display: grid;
  justify-content: center;
}
.twoUp__tablebody {
  margin-top: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  /* width: 100%; */
}
.twoUp__tablerowbodyspan {
  align-items: center;
  font-size: 2.625em;
  color: #ffffff;
}
.twoUp__bodyseparator {
  background-color: #0f0f0f;
  width: 2px;
  height: 3.375em;
}
.twoUp_doneButton {
  width: 2.354333333em;
  height: 0.916666667em;
}
.twoUp__dropdown {
  margin: 10px;
}
.twoUp__table-body-name {
  display: grid;
  grid-row: 1;
  grid-template-columns: 2.85em 1fr 1fr;
  grid-template-rows: 1em;
  margin-left: 10px;
  text-transform: uppercase;
  align-content: center;
}
.twoUp__table-body-name-standarduser {
  display: grid;
  grid-row: 1;
  grid-template-columns: 1fr 1fr;
  margin-left: 20px;
  text-transform: uppercase;
}
.twoUp__table-body-nametext {
  grid-row: 1;
  grid-column: 2 / 4;
  white-space:nowrap;
  margin-top: 3px; /*HACK*/ 
  background: linear-gradient(to right, rgba(255,255,255,1) 2%,rgba(255,255,255,1) 90%,rgba(255,255,255,0) 100%); 
  text-overflow: ellipsis;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: -2.9em;
}
.twoUp__table-body-nametext-standardUser {
  grid-row: 1;
  grid-column: 1 / 3;
}
.twoUp__table-body-warning {
  grid-row: 1;
  grid-column: 2 / 4;
  justify-self: end;
  background: #d0021b;
  border-radius: 0.4em;
  padding-left: 0.35em;
  padding-right: 0.35em;
  padding-bottom: 0.1em;
  margin-right: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
  -webkit-filter: drop-shadow(-10px 0 2rem rgba(0, 0, 0, 0.5));
          filter: drop-shadow(-10px 0 2rem rgba(0, 0, 0, 0.5));
  white-space:nowrap;
  overflow:hidden;
  height: 1em;
  margin-left: 10px;
  font-size: 2.5em;
}
.twoUp__table-alert {
  width: 0.6em;
  height: 0.6em;
  padding-left: 5px;
  padding-right: 10px;
}
.twoUp__table-lsm {
  display: grid;
  justify-content: center;
}
.twoUp__donebuttoncontainer {
  margin-right: 10px;
  margin-left: 0px;
  display: grid;
  align-items: center;
  justify-content: left;
  height: 100%;
}
.twoUp__spincontainer {
  margin-left: 10px;
}

.react-tiny-popover-container {
  -webkit-filter: drop-shadow(-0px 0 8rem rgba(0, 0, 0, 1));
          filter: drop-shadow(-0px 0 8rem rgba(0, 0, 0, 1));
}
.twoUp__binpopover {
  background: #1a1a1a;
  border: 1px solid #595959;
  box-shadow: inset 0 1px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8.4px;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-content: left;
  font-weight: 900;
  font-size: 3.000em;
  color: #ffffff;
  letter-spacing: 0;
}
.twoUp__binpopovername {
  border-bottom: 1px solid #3b3b3b;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: left;
  text-transform: uppercase;
}
.twoUp__binpopovername:last-child {
  border-bottom: 0;
}
.twoUp__binpopover-container {
  height: 12em;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-content: left;
}
.twoUp__binpopovername-text {
  margin-left: 20px;
  margin-right: 150px;
  justify-self: start;
}
.twoUp__binpopovername:hover {
  background: #0075e1;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.twoUp__gear {
  justify-self: end;
  margin-right: 34px;
  cursor: pointer;
}
.twoUp__preferencespopovercontainer {
  width: 50em;
  min-width: 320px;
  min-height: 120px;
}
.twoUp__preferencespopover {
  background: #1a1a1a;
  border: 1px solid #595959;
  box-shadow: 0 2px 32px 5px rgba(0, 0, 0, 0.5),
    inset 0 1px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-content: left;
  font-weight: 900;
  font-size: 2.000em;
  color: #ffffff;
  letter-spacing: 0;
  margin-right: 50px;
  height: 8em;
  text-transform: uppercase;
  min-width: 320px;
  min-height: 120px;
}
.twoUp_prefsettingtext {
  text-transform: uppercase;
  margin-left: 20px;
  width: 14em;
}
.twoUp_settingcontainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-auto-columns: 1fr 1fr;
}
.twoUp__rowsizecontainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-auto-columns: 1fr 1fr;
}
.twoUp__rowsizevalue {
  margin-left: 20px;
  margin-right: 20px;
}

.twoUp_viewtypecontainersingle {
  background: #0075e1;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  justify-self: end;
  margin-right: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
}
.twoUp__rowsizecontrolscontainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-self: end;
  margin-right: 20px;
}
.dropdown_small {
  margin-left: 10px;
} 
::-webkit-scrollbar {
  width: 0px;
  background: transparent; 
}
.twoUp__adminlinecontainer {
  margin-top: 0.625em;
  height: 5px;
  width: 100%;
  overflow: auto;
}
.twoUp__adminline {
  background: #fff;
  height: 3px;
  width: 100%;
}
.twoUp__fontsizeinput  {
  background:none;
  outline: none;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 1em;
  color:#fff;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border-width: 0 0 1px;
  border-radius: 0px;
  -webkit-border-radius:0px;
  -moz-border-radius:0px;
  outline:none;
  text-align: center;
  width: 4em;
}
.twoUp__whiteseparator {
  background-color: #FFF;
  /* margin-top: calc((3.5em * 0) + (15px * 0) - 8px); */
  height: 3px;
  width: 100%;
  position: absolute;
}
.twoUp_logout {
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.5em;
}
.twoUp__prefinfo {
  font-size: 0.5em;
  color:#555;
  width: 26em;
}
.Login {
    display: grid;
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100%;
  }
  .Login__container {
    background: #1D1D1D;
    color: #EFF3FA;
    width: 724px;
    height: 300px;
    display: inline-grid;
    grid-template-columns:  50% 1px 50%;
    grid-template-rows:  100%;
    justify-content: center;
    align-content: center;
  }
  .Login__titlecontainer {
    width:100%;
    height:100%;
  }
  .Login__title {
    letter-spacing: 3.94px;
    text-align: right;
    font-size: 42px;
    height: 100%;
    width: 100%;
    display:inline-grid;
    justify-content: center;
    align-content: center;
  }
  .Login__company {
    font-size: 42px;
    font-weight: 300;
  }
  .Login__project {
    font-weight: 900;
  }
  .Login__fields {
    height: 100%;
    width: 100%;
    display:inline-grid;
    justify-content: center;
    align-content: center;
    font-weight: 300;
    border-width: 0 0 1px;
    margin-top:20px;
  }
  .Login__vl {
    border-right: 1px solid rgba(228, 234, 246, 0.2);
    height: 180px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .Login__username {
    font-size: 16px;
    letter-spacing: 3px;
    background: transparent;
    border-color: #979797;
    width:281px;
    margin-bottom: 37px;
    margin-top: 30px;
    color:#fff;
  }
  .Login__password {
    font-size: 16px;
    letter-spacing: 3px;
    background: transparent;
    border-color: #979797;
    width:281px;
    color:#fff;
  }
  input::-webkit-input-placeholder {
    color: #FFF;
    
  }
  input:-ms-input-placeholder {
    color: #FFF;
    
  }
  input::-ms-input-placeholder {
    color: #FFF;
    
  }
  input::placeholder {
    color: #FFF;
    
  }
  input {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    border-width: 0 0 1px;
    border-radius: 0px;
    -webkit-border-radius:0px;
    -moz-border-radius:0px;
    outline:none;
  }
  .Login__login{
    margin-left: auto;
    cursor: pointer;
    
  }
  .Login__invalid {
    margin-right: auto;
    color:#f00;
  }
  .Login__footer {
    margin-top: 30px;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
  }
html{height:100%}body{background:#1a1a1a;margin:0;font-family:"Roboto", sans-serif;font-weight:900;color:#fff;font-size:16px;display:flex;justify-content:center;align-items:center}#root{position:relative;width:100%}#root:after{content:'';display:block}.App{position:absolute;top:0;bottom:0;width:100%;height:100%;display:flex;box-sizing:border-box}.App:after{margin:auto;display:block}.content{background:#555;box-shadow:inset 1vh 1vh 10vh 0 rgba(0,0,0,0.5);position:absolute;top:0;bottom:0;width:100%;height:100%;display:flex;box-sizing:border-box;border:25px solid #cecece}.content:after{margin:auto;display:block;color:#fff;position:relative}.dot{height:32px;width:32px;border-radius:50%;display:inline-block;border:1px solid #333333;margin-left:34px}.redDot{background-color:#c90c0c;box-shadow:0 0 16px rgba(201,12,12,0.25);-moz-box-shadow:0 0 16px rgba(201,12,12,0.25);-webkit-box-shadow:0 0 16px rgba(201,12,12,0.25);-o-box-shadow:0 0 16px rgba(201,12,12,0.25)}.yellowDot{background-color:#c9c60c;box-shadow:0 0 16px rgba(198,201,12,0.25);-moz-box-shadow:0 0 16px rgba(198,201,12,0.25);-webkit-box-shadow:0 0 16px rgba(198,201,12,0.25);-o-box-shadow:0 0 16px rgba(198,201,12,0.25)}.greenDot{background-color:#22c90c;box-shadow:0 0 16px rgba(34,201,12,0.25);-moz-box-shadow:0 0 16px rgba(34,201,12,0.25);-webkit-box-shadow:0 0 16px rgba(34,201,12,0.25);-o-box-shadow:0 0 16px rgba(34,201,12,0.25)}

