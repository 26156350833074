a:visited {
  color: #777;
}
.onePanel {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  resize: horizontal;
  width: 100%;
}
.oneUp__fullscreenbutton {
  position: absolute;
  right:5px;
  top:5px;
  cursor: pointer;
}
.onePanel__panel {
  background: #272727;
  border: 1px solid #16171a;
  border: 1px solid #4c4c4c;
  box-shadow: inset 0 0 2px 0 rgba(92, 92, 92, 0.05);
  border-radius: 8.4px;
  width: 100%;  
  display: grid;
  grid-row: 3;
  grid-auto-flow: row dense;
  grid-auto-rows: auto auto auto;
  align-content: start;
  height: 100%;
}
.oneUp__header {
  display: grid;
  background: #232323;
  border: 1px solid #191919;
  border-radius: 7.35px 7.35px 0 0;
  border-bottom: 1px solid #454545;
  width: calc(100% - 2px);
  height: 6.125em;
  grid-auto-flow: column;
  align-items: center;
  justify-content: left;
  grid-auto-columns: min-content max-content 1fr;
  min-height: 64px;
}
.oneUp__header-text {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 4.500em;
  color: #7c7c7c;
  letter-spacing: 0;
  margin-left: 34px;
  text-transform: uppercase;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  cursor: pointer;
  width: 100%;
}

.oneUp__tablefeedcontainer {
  /* overflow-y: auto; */
  width: 100%;
}
.oneUp__droppable {
  width: 100%;
}
::-webkit-scrollbar {
  display: none;
}
.oneUp__tablefeed {
  white-space: nowrap;
  box-sizing: content-box;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  overflow: -moz-scrollbars-none;
  padding-bottom: 20px;
  
  /* height: 1000px; */
  /* height:3em; */
}
.oneUp__tablefeed-empty {
  /* background-color: #f55; */
  /* white-space: nowrap;
  overflow-y: scroll;
  box-sizing: content-box;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  overflow: -moz-scrollbars-none; */
  padding-bottom: 20px;
  height: 100%;
  width: 100%;
  /* height: 100px; */
  /* width:100px; */
}

.oneUp__table-row-content {
  display: grid;
  grid-auto-flow: column;
  height: 3.375em;
  background: #19191a;
  border: 1px solid #0b0b0b;
  box-shadow: 0 1px 1px -1px rgba(255, 255, 255, 0.5),
    inset 0 2px 17px 0 rgba(0, 0, 0, 0.5);
  border-radius: 8.4px;
  margin-left: 20px;
  margin-right: 20px;
  grid-auto-columns: auto 1px 10.688em 1px 16.250em 1px 16.250em;
}
.oneUp__table-row-content-warning {
  display: grid;
  grid-auto-flow: column;
  height: 5.982142855em;
  background: #19191a;
  border: 1px solid #0b0b0b;
  box-shadow: 0 1px 1px -1px rgba(255, 255, 255, 0.5),
    inset 0 2px 17px 0 rgba(0, 0, 0, 0.5);
  border-radius: 8.4px;
  margin-left: 20px;
  margin-right: 20px;
  grid-template-columns: auto 1px 10.688em 1px 16.250em 1px 16.250em;
  grid-column-start: 1;
  grid-column-end: 8;
  grid-template-rows: 3.375em 2.607142855em;
}
.oneUp__tablerowbodyspan-warning {
  border-top: 1px solid #323234;
  display: grid;
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-end: 2;
  justify-content: left;
  align-content: center;
}
.oneUp__table-row-content-inactive {
  background: #363636;
}
.oneUp__table-row-content-played {
  background: #307A23;
}
.separater {
  border: 1px solid #454545;
}
.oneUp__table-header {
  margin-top: 28px;
  margin-bottom: 0px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  width: 100%;
}
.oneUp_rowheaderspan {
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 2.362em;
  color: #ffffff;
}
.oneUp_headerseparator {
  background-color: #0f0f0f;
  grid-row-start: 1;
  grid-row-end: 1;
  width: 2px;
}

.oneUp__table-duration {
  display: grid;
  justify-content: center;
  grid-row-start: 1;
  grid-row-end: 1;
}
.oneUp__table-created {
  display: grid;
  justify-content: center;
  grid-row-start: 1;
  grid-row-end: 1;
}
.oneUp__tablebody {
  margin-top: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  /* width: 100%; */
}
.oneUp__tablerowbodyspan {
  align-items: center;
  font-size: 2.625em;
  color: #ffffff;
}
.oneUp__bodyseparator {
  background-color: #0f0f0f;
  width: 2px;
  height: 3.375em;
}
.oneUp_doneButton {
  width: 2.354333333em;
  height: 0.916666667em;
}
.oneUp__dropdown {
  margin: 10px;
}
.oneUp__table-body-name {
  display: grid;
  grid-row: 1;
  grid-template-columns: 2.85em 1fr 1fr;
  grid-template-rows: 1em;
  margin-left: 10px;
  text-transform: uppercase;
  align-content: center;
}
.oneUp__table-body-name-standarduser {
  display: grid;
  grid-row: 1;
  grid-template-columns: 1fr 1fr;
  margin-left: 20px;
  text-transform: uppercase;
}
.oneUp__table-body-nametext {
  grid-row: 1;
  grid-column: 2 / 4;
  white-space:nowrap;
  margin-top: 3px; /*HACK*/
  /* overflow:hidden; */
  background: -webkit-linear-gradient(left, rgba(255,255,255,1) 2%,rgba(255,255,255,1) 90%,rgba(255,255,255,0) 100%);
  background: -moz-linear-gradient(left, rgba(255,255,255,1) 2%, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%); 
  background: linear-gradient(to right, rgba(255,255,255,1) 2%,rgba(255,255,255,1) 90%,rgba(255,255,255,0) 100%); 
  text-overflow: ellipsis;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: -2.9em;
  /* justify-self: left; */
}
.oneUp__table-body-nametext-standardUser {
  grid-row: 1;
  grid-column: 1 / 3;
}
.oneUp__table-body-warning {
  grid-row: 1;
  grid-column: 2 / 4;
  justify-self: end;
  background: #d0021b;
  border-radius: 0.4em;
  padding-left: 0.35em;
  padding-right: 0.35em;
  padding-bottom: 0.1em;
  margin-right: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
  filter: drop-shadow(-10px 0 2rem rgba(0, 0, 0, 0.5));
  white-space:nowrap;
  overflow:hidden;
  /* max-width: 100%;
  max-height: 90%; */
  height: 1em;
  margin-left: 10px;
  font-size: 2.5em;
}
.oneUp__table-body-warning-single {
  grid-row: 1;
  grid-column: 2 / 4;
  justify-self: end;
  background: #d0021b;
  border-radius: 0.4em;
  padding-left: 0.35em;
  padding-right: 0.35em;
  padding-bottom: 0.1em;
  margin-right: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
  filter: drop-shadow(-10px 0 2rem rgba(0, 0, 0, 0.5));
  white-space:nowrap;
  overflow:hidden;
  /* max-width: 100%;
  max-height: 90%; */
  height: 1em;
  margin-left: 10px;
  /* font-size: 2em; */
}
.oneUp__table-alert {
  width: 0.6em;
  height: 0.6em;
  padding-left: 5px;
  padding-right: 10px;
}
.oneUp__table-lsm {
  display: grid;
  justify-content: center;
}
.oneUp__donebuttoncontainer {
  margin-right: 10px;
  margin-left: 0px;
  display: grid;
  align-items: center;
  justify-content: left;
  height: 100%;
}
.oneUp__spincontainer {
  margin-left: 10px;
}

.react-tiny-popover-container {
  filter: drop-shadow(-0px 0 8rem rgba(0, 0, 0, 1));
}
.oneUp__binpopover {
  background: #1a1a1a;
  border: 1px solid #595959;
  box-shadow: inset 0 1px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8.4px;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-content: left;
  font-weight: 900;
  font-size: 3.000em;
  color: #ffffff;
  letter-spacing: 0;
}
.oneUp__binpopovername {
  border-bottom: 1px solid #3b3b3b;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: left;
  text-transform: uppercase;
}
.oneUp__binpopovername:last-child {
  border-bottom: 0;
}
.oneUp__binpopover-container {
  height: 12em;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-content: left;
}
.oneUp__binpopovername-text {
  margin-left: 20px;
  margin-right: 150px;
  justify-self: start;
}
.oneUp__binpopovername:hover {
  background: #0075e1;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.oneUp__gear {
  justify-self: end;
  margin-right: 34px;
  cursor: pointer;
}
.oneUp__preferencespopovercontainer {
  width: 850px;
  min-width: 320px;
  min-height: 120px;
}
.oneUp__preferencespopover {
  background: #1a1a1a;
  border: 1px solid #595959;
  box-shadow: 0 2px 32px 5px rgba(0, 0, 0, 0.5),
    inset 0 1px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-content: left;
  font-weight: 900;
  font-size: 36px;
  color: #ffffff;
  letter-spacing: 0;
  margin-right: 50px;
  height: 8em;
  text-transform: uppercase;
  min-width: 320px;
  min-height: 120px;
}
.oneUp_prefsettingtext {
  text-transform: uppercase;
  margin-left: 20px;
  width: 14em;
}
.oneUp_settingcontainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-auto-columns: 1fr 1fr;
}
.oneUp__rowsizecontainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-auto-columns: 1fr 1fr;
}
.oneUp__rowsizevalue {
  margin-left: 20px;
  margin-right: 20px;
}

.oneUp_viewtypecontainersingle {
  background: #0075e1;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  justify-self: end;
  margin-right: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
}
.oneUp__rowsizecontrolscontainer {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-self: end;
  margin-right: 20px;
}
.dropdown_small {
  margin-left: 10px;
} 
::-webkit-scrollbar {
  width: 0px;
  background: transparent; 
}
.oneUp__adminlinecontainer {
  margin-top: 0.625em;
  height: 5px;
  width: 100%;
  /* width: 1916px; */
  /* position: absolute; */
  overflow: auto;
  /* margin-top: 896px; */
}
.oneUp__adminline {
  background: #fff;
  height: 3px;
  width: 100%;
}
.oneUp__fontsizeinput  {
  background:none;
  outline: none;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 1em;
  color:#fff;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border-width: 0 0 1px;
  border-radius: 0px;
  -webkit-border-radius:0px;
  -moz-border-radius:0px;
  outline:none;
  text-align: center;
  width: 4em;
}
.oneUp__whiteseparator {
  background-color: #FFF;
  /* margin-top: calc((3.5em * 0) + (15px * 0) - 8px); */
  height: 3px;
  width: 100%;
  position: absolute;
}
.oneUp_logout {
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.5em;
}
.oneUp__prefinfo {
  font-size: 0.5em;
  color:#555;
  width: 26em;
}